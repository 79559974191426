import "./style.scss";
import { IonItemGroup, IonPopover, IonText } from "@ionic/react";
import { SelectedFile } from "@src/app/shiftSignature/timecard/model";
import { Shift } from "@src/lib/interface";
import { useState } from "react";

import { FileExplorer } from "./fileExplorer";
import { FilePreviewAndUpload } from "./previewAndUpload";
import { enterAnimation, leaveAnimation } from "../../rating/reviewPrompt/popoverAnimation";

const UploadFileModal: React.VFC<{
  shift: Shift;
  onCloseOrCancel: () => void;
  onSuccessfulUpload: () => void;
}> = ({ shift, onCloseOrCancel, onSuccessfulUpload }) => {
  const [selectedFile, setSelectedFile] = useState<SelectedFile | null>(null);
  return (
    <IonPopover
      isOpen
      showBackdrop
      backdropDismiss={false}
      enterAnimation={enterAnimation}
      leaveAnimation={leaveAnimation}
      cssClass="upload-prompt"
    >
      <div id="overlay-section" className="timesheet-upload">
        <IonItemGroup className="upload-time-group">
          {selectedFile ? (
            <FilePreviewAndUpload
              selectedFile={selectedFile}
              onPreviewClosed={() => {
                setSelectedFile(null);
                onCloseOrCancel();
              }}
              onSuccessfulUpload={() => {
                setSelectedFile(null);
                onSuccessfulUpload();
              }}
              shift={shift}
            />
          ) : (
            <FileExplorer onFileSelected={setSelectedFile} />
          )}
          <div
            role={"button"}
            tabIndex={0}
            onClick={onCloseOrCancel}
            className="ion-text-center ion-margin file-upload-cancel-btn"
          >
            <IonText>Cancel</IonText>
          </div>
        </IonItemGroup>
      </div>
    </IonPopover>
  );
};

export { UploadFileModal };
